import React from "react";
import LogoutDropdown from "./Logout";
import CustomTooltip from "../Common/CustomTooltip";

const Header = ({
  title,
  tooltipContent,
  tooltipPlacement,
  infoTooltip,
  tooltipContentTwo,
  tooltipContentThree,
  tooltipContentFour,
}) => {
  return (
    <div className="header">
      <div className="title">
        {title}

        <CustomTooltip
          content={tooltipContent}
          contentTwo={tooltipContentTwo}
          contentThree={tooltipContentThree}
          contentFour={tooltipContentFour}
          placement={tooltipPlacement}
          infoTooltip={infoTooltip}
        />
      </div>
      <div className="welcome-user">
        <LogoutDropdown />
      </div>
    </div>
  );
};

export default Header;
