import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { getAllServices } from "../../api/users";

import PublishClaims from "./PublishClaims";
import Header from "../Header/Header";
import EmptyScreen from "../Common/EmptyScreen";
import { toastWarning } from "../Common/ToastAlert";
import Widget from "../Common/Widget";

import "../../assets/sass/_add-service.scss";
import Files from "../../assets/images/files-duotone.svg";
import EmptyIllustratorIcon from "../../assets/images/no-services.svg";

const Services = () => {
  const navigate = useNavigate();
  // const { issuerId } = useContext(StepperContext);
  const issuerId = localStorage.getItem("issuerId");

  const [openModal, setOpenModal] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [count, setCount] = useState({});
  const [filteredAndSortedServices, setFilteredAndSortedServices] = useState(
    []
  );

  const emptyScreenContent = {
    heading: "No Service added yet",
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const getServices = await getAllServices(issuerId);
      const res = getServices.data.result;
      setServices(res);
      setCounts(res);
      const sortedData = res.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setFilteredAndSortedServices(sortedData);
      setFilteredData(sortedData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenPublishModal = (service) => {
    setSelectedService(service);
    setOpenModal(true);
  };

  const setCounts = (services) => {
    const data = {
      unpublished: 0,
      published: 0,
    };
    services.map((x) => {
      if (x.published == true) data.published += 1;
      else if (x.published == false) data.unpublished += 1;
    });
    setCount(Object.assign({}, data));
  };

  const allServicesFilter = () => {
    setFilteredAndSortedServices(filteredData);
  };
  const publishedServicesFilter = () => {
    const filteredPublishedService = filteredData.filter(
      (item) => item.published === true
    );
    setFilteredAndSortedServices(filteredPublishedService);
  };
  const upublishedServicesFilter = () => {
    const filteredUnpublishedService = filteredData.filter(
      (item) => item.published === false
    );
    setFilteredAndSortedServices(filteredUnpublishedService);
  };

  const tooltipContent = ` 
             Issuers configure services on the platform by using Schema & Credential Definitions.
                        `;
  const tooltipContentTwo = ` 
           A schema definition in SSI specifies the structure of the data that a verifiable credential (VC) will contain. It defines the types of information that can be included in a credential and sets the format for this data.
                        `;
  const tooltipContentThree = ` 
             A credential definition in SSI builds on the schema definition by providing the specifics needed to issue a particular type of credential. It includes details about the issuer and the cryptographic mechanisms used to ensure the integrity and authenticity of the credential.
            `;

  return (
    <>
      {openModal && (
        <PublishClaims
          show={openModal}
          onHide={() => setOpenModal(false)}
          data={selectedService}
          setOpenModal={setOpenModal}
          refetchData={fetchData}
        />
      )}
      <div className="right-container">
        <div className="main-header">
          <Header
            title="Services"
            infoTooltip={true}
            tooltipContent={tooltipContent}
            tooltipContentTwo={tooltipContentTwo}
            tooltipContentThree={tooltipContentThree}
            tooltipPlacement="right"
          />
        </div>
        <div className="inner-container">
          <div className="inner-body-container service-conatiner">
            <div className="header d-flex align-items-center justify-content-between">
              <div className="custom-select">
                <div className="select-box">
                  <button
                    type="button"
                    className="dsm-btn-primary add-new-service-wiz"
                    onClick={() => navigate("/services/add-service")}
                  >
                    Add New Service
                  </button>
                </div>
              </div>
              <h3 className="sub-title">All Services</h3>
            </div>

            <div className="body">
              <div className="p-2">
                <div className="services-data-section widget">
                  <Widget
                    borderColor="b"
                    icon="circle-fill"
                    color="blue"
                    title="Total
                        Services"
                    value={services.length ? services.length : "0"}
                    filter={allServicesFilter}
                  />

                  <Widget
                    borderColor="g"
                    icon="circle-fill"
                    color="green"
                    title="Published
                        Services"
                    value={count.published ? count.published : "0"}
                    filter={publishedServicesFilter}
                  />

                  <Widget
                    borderColor="o"
                    icon="circle-fill"
                    color="orange"
                    title="Unpublished
                        Services"
                    value={count.unpublished ? count.unpublished : "0"}
                    filter={upublishedServicesFilter}
                  />

                  {/* <Widget
                    borderColor="b"
                    icon="check-circle-fill"
                    color="blue"
                    title="Verifiers"
                    value={services.length ? services.length : "0"}
                    filter={allServicesFilter}
                  /> */}
                </div>
                <hr className="mb-4" />
                <div
                  className={`all-services-cards ${
                    services.length === 0
                      ? " d-flex gap-3 flex-wrap justify-content-center"
                      : ""
                  }`}
                >
                  {loading ? (
                    <div className="text-center mt-5">
                      <div className="w-100 p-4 d-flex align-items-center">
                        <Spinner
                          animation="border"
                          size="lg"
                          variant="primary"
                        />
                      </div>
                    </div>
                  ) : services.length == 0 ? (
                    <div className="text-center">
                      <EmptyScreen
                        content={emptyScreenContent}
                        emptyScreenImage={EmptyIllustratorIcon}
                      />
                    </div>
                  ) : (
                    filteredAndSortedServices.map((service) => (
                      <div
                        key={service?.id}
                        className="service-card service-card-wiz"
                        onClick={() => handleOpenPublishModal(service)}
                      >
                        <div>
                          {service?.published ? (
                            <span className="success-badge badge-sm">
                              Published
                            </span>
                          ) : (
                            <span className="pending-badge badge-sm">
                              Unpublished
                            </span>
                          )}
                        </div>
                        <div className="card-body text-center fw-bold">
                          {service?.published ? (
                            <i class="bi bi-database-fill-check text-color-primary p-1"></i>
                          ) : (
                            <i class="bi bi-database-gear text-color-primary p-1"></i>
                          )}

                          <p className="text-capitalize ">{service.name}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
