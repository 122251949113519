import React, { useCallback } from "react";

const Stepper = ({ currentStep, stepName }) => {
  const getCurrentStepClass = useCallback(
    (step) => {
      if (step === currentStep) {
        return "active";
      }
      if (step < currentStep) {
        return "completed";
      }
      return "inactive";
    },
    [currentStep]
  );

  return (
    <div className="stepper-wrapper">
      <div className="stepper">
        {stepName.map((step, index) => (
          <div className={`stepper-item ${getCurrentStepClass(index + 1)}`} key={index}>
            <div className="step">
              <div className="step-counter-wrapper">
                <div className="step-counter">
                  {getCurrentStepClass(index + 1) === "completed"
                    ? null
                    : index + 1}
                </div>
              </div>
              <div className="stepper-text">{step}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
