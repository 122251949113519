import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import TextField from "../../../../Common/TextField";
import { toastSuccess } from "../../../../Common/ToastAlert";
import customStyles from "../../../../../assets/sass/common/selectStyles";

const SqlConnector = ({ setIsSqlConnectorValid }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  const options = [
    { value: "MySQL", label: "MySQL" },
    { value: "MongoDB", label: "MongoDB" },
  ];

  const watchAllFields = watch();

  useEffect(() => {
    const isFormValid =
      watchAllFields.dbType && watchAllFields.connectionString;
    setIsSqlConnectorValid(isFormValid);
  }, [watchAllFields, setIsSqlConnectorValid]);

  const onSubmit = (data) => {
    if (!data.dbType || !data.connectionString) {
      toast.error("Please fill in all fields");
      return;
    }

    toast.success("Database connection successfully tested", toastSuccess());
  };

  return (
    <>
      <div className="chose-title">
        <h2>Set your database connection string</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mt-4">
          <Col lg={3}>
            <label className="inputbox-label">Type</label>
            <div className="textfield-container">
              <Controller
                name="dbType"
                control={control}
                defaultValue={selectedOption}
                rules={{ required: "Please select a database type" }}
                render={({ field }) => (
                  <Select
                  styles={customStyles}
                    {...field}
                    closeMenuOnSelect={true}
                    onChange={(option) => {
                      field.onChange(option);
                      setSelectedOption(option);
                    }}
                    options={options}
                    placeholder="Select"
                  />
                )}
              />
              {errors.dbType && (
                <span className="inputbox-error-msg">
                  {errors.dbType.message}
                </span>
              )}
            </div>
          </Col>
          <Col lg={6}>
            <Controller
              control={control}
              name="connectionString"
              rules={{ required: "Connection string is required" }}
              render={({ field: { onChange, value, onBlur } }) => (
                <TextField
                  label="Connection String"
                  type="text"
                  placeholder="connection string"
                  maxLength={40}
                  resize="none"
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  error={errors.connectionString?.message}
                />
              )}
            />
          </Col>
          <Col lg={3} className="mt-4">
            <button type="submit" className="dsm-btn-secondary">
              Test
            </button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default SqlConnector;
