import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Header from "../Header/Header";
import TextField from "../Common/TextField";
import {
  getNetworkDetails,
  getUser,
  getUserByEmail,
  updateUser,
  isUserPrimary
} from "../../api/users";
import UserService from "../../keycloak/UserService";
import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";
import TextArea from "../Common/TextArea";

const Profile = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [did, setDid] = useState("");

  const [keycloakData, setKeycloakData] = useState([]);
  const [networkDetails, setNetworkDetails] = useState([]);
  const [editable, setEditable] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [error, setError] = useState("");
  const [isCancelled, setIsCancelled] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      website: "",
      country: "",
      address: "",
    },
  });

  const errorMessage = {
    website: {
      required: "Website is required",
      invalid: "Enter a valid Website URL",
    },
    country: {
      required: "Country is required",
      invalid: "Enter a valid Country Name",
    },
    address: {
      invalid: "Enter a valid Address",
    },
  };

  useEffect(() => {
    const checkPrimaryStatus = async () => {
      const email = UserService.getUsername()?.email;
      localStorage.setItem('username', email);
      if (!email) {
        navigate("/welcome");
        return;
      }

      try {
        const response = await isUserPrimary(email);
        const isPrimaryUser = response || false;
        if (isPrimaryUser.success) {
          localStorage.setItem('issuerID', response.data.id);
          localStorage.setItem('cloudInfo', JSON.stringify(response.data.cloudInfo));
          localStorage.setItem('orgName', response.data.orgName);
          navigate("/pin-setup");
        } else {
          setIsPrimary(true);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error checking primary status:", error);
        navigate("/profile");
      }
    };

    checkPrimaryStatus();
  }, [navigate]);

  useEffect(() => {
    const setLoggedIn = async () => {
      if (!sessionStorage.getItem("issuerDataInitialized")) {
        const email = UserService.getUsername()?.email;
        try {
          const sendEmail = await getUserByEmail({ email: email });
          const result = sendEmail?.data?.result;
          setKeycloakData(result);

          localStorage.setItem("networkId", result?.networkId);
          localStorage.setItem("issuerId", result?.issuerId);
          localStorage.setItem("userId", result?.id);

          sessionStorage.setItem("issuerDataInitialized", "true");
        } catch (error) {
          toast.warning(error.response.data.message, toastWarning());
        }

        setIsInitialized(true);
      } else {
        setIsInitialized(true);
      }
    };

    const initialize = async () => {
      await setLoggedIn();
    };

    initialize();
  }, []);

  useEffect(() => {
    const issuerId = localStorage.getItem("issuerId");
    const logTheUser = async () => {
      if (!issuerId) return;

      try {
        const networkName = await getNetworkDetails(
          localStorage.getItem("networkId")
        );

        const getLoggedInUser = await getUser(issuerId);

        const userData = getLoggedInUser.data.result;
        setNetworkDetails(networkName?.data.result);
        setLoggedInUser(userData);

        setValue("website", userData?.website || "");
        setValue("country", userData?.country || "");
        setValue("address", userData?.address || "");

        if (userData.website && userData.country && userData.address) {
          setEditable(false);
          setEditButton(true);
        } else {
          setEditable(false);
        }
        return userData;
      } catch (error) {
        toast.warning(
          error.response?.data?.message || "Error fetching user data",
          toastWarning()
        );
      }
    };

    if (isInitialized && issuerId) {
      logTheUser()
        .then(async (res) => {
          if (res && res.active === false) {
            const randomString = [...Array(22)]
              .map(() => Math.random().toString(36)[2])
              .join("");
            try {
              const data = {
                id: issuerId,
                active: true,
                issuerDid: `${randomString}`,
              };
              const onUpdate = await updateUser(data);

              if (onUpdate.status === 200) {
                toast.success("Profile updated successfully", toastSuccess());
                const newDid = onUpdate.data.result.issuerDid;
                setDid(newDid);
              } else {
                toast.error(onUpdate.message, toastError());
              }
            } catch (error) {
              setError(error.response.data.message);
              toast.warning(error.response.data.message, toastWarning());
            }
          }
        })
        .catch((error) => {});
    }
  }, [isInitialized, setValue]);

  const updateUserDetails = async (data) => {
    setLoading(true);
    try {
      const onUpdate = await updateUser({
        ...data,
        id: localStorage.getItem("issuerId"),
        networkId: localStorage.getItem("networkId"),
        orgName: loggedInUser?.orgName,
        userName: loggedInUser?.userName,
      });

      setLoading(false);
      if (onUpdate.status === 200) {
        toast.success("Profile updated successfully", toastSuccess());
        setEditable(false);
        setEditButton(true);
      } else {
        toast.error(onUpdate.message, toastError());
      }
    } catch (error) {
      setLoading(false);
      setError(error.response?.data?.message || "Error updating profile");
      toast.warning(
        error.response?.data?.message || "Error updating profile",
        toastWarning()
      );
    }
  };

  const cancelChanges = () => {
    // reset();
    setIsCancelled(true);
    setEditable(false);
    setEditButton(true);
  };

  const tooltipContent = ` 
            The Issuer is a trusted entity within the self-sovereign identity
            (SSI) ecosystem responsible for attesting verifiable credentials
            (VCs) that represent the achievements, certifications, or attributes
            of holders.
                        `;
  const tooltipContentTwo = ` 
            The Issuer's primary focus is on ensuring the accuracy
            and verifiability of these VCs. This involves defining credential
            schemas and credential definitions that specify the data structure
            and type of information encapsulated within the VCs, such as
            diplomas or KYC verifications.
                        `;
  const tooltipContentThree = ` 
            After thoroughly verifying the holder's information, the Issuer
            attests the VCs in accordance with these predefined schemas,
            digitally signing them to provide cryptographic proof of
            authenticity and integrity.
            `;
  const tooltipContentFour = `By ensuring the precision and validity
            of the attested credentials, the Issuer upholds a reputation of
            trustworthiness, enabling verifiers to reliably authenticate and
            trust the information contained within the VCs.
            `;
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isPrimary) {
    return null;
  }

  return (
    <>
      <div className="right-container">
        <div className="main-header">
          <Header
            title="Profile"
            infoTooltip={true}
            tooltipContent={tooltipContent}
            tooltipContentTwo={tooltipContentTwo}
            tooltipContentThree={tooltipContentThree}
            tooltipContentFour={tooltipContentFour}
            tooltipPlacement="right"
          />
        </div>
        <div className="inner-container">
          <div className="inner-body-container">
            <div className="header">
              <div className="custom-select d-flex align-items-center">
                <div className="d-flex align-content-center gap-2">
                  {editButton && (
                    <button
                      type="button"
                      className="dsm-btn-secondary edit-details-wiz"
                      onClick={() => {
                        setEditable(true);
                        setEditButton(false);
                      }}
                    >
                      Edit Details
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="body">
              <div className="view-fields">
                <Row className="d-flex justify-content-start align-items-center">
                  <Col lg={3} md={12} xl={3}>
                    {/* <i className={`bi bi-boxes text-info`}></i>
                     <i className={`bi bi-command text-info`}></i>
                      <i className={`bi bi-diagram-3 text-info`}></i> */}
                    {/* <i className={`bi bi-bounding-box-circles text-info `}></i> */}
                    <Row className="d-flex justify-content-center align-items-center">
                      <Col lg={2} md={2} xl={2} sm={2} xs={2}>
                        <i className={`bi bi-broadcast text-color-primary`}></i>
                      </Col>
                      <Col
                        className="fs-6 lh-base"
                        lg={8}
                        md={8}
                        xl={8}
                        sm={8}
                        xs={8}
                      >
                        <span className="fw-light text-secondary text-label">
                          Network Name
                        </span>
                        <br />
                        <span className="fw-bold text-label">
                          {networkDetails?.name || "-"}
                        </span>
                      </Col>
                      <Col lg={2} md={2} xl={2} sm={2} xs={2} className="my-1">
                        <div className="vr "></div>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={3} md={12} xl={3}>
                    <Row className="d-flex justify-content-center align-items-center">
                      <Col lg={2} md={2} xl={2} sm={2} xs={2}>
                        <i
                          className={`bi bi-building-fill text-color-primary`}
                        ></i>
                      </Col>
                      <Col
                        className="fs-6 lh-base"
                        lg={8}
                        md={8}
                        xl={8}
                        sm={8}
                        xs={8}
                      >
                        <span className="fw-light text-secondary text-label">
                          Organization Name
                        </span>
                        <br />
                        <span className="fw-bold text-label">
                          {loggedInUser?.orgName || "-"}
                        </span>
                      </Col>
                      <Col lg={2} md={2} xl={2} sm={2} xs={2} className="my-1">
                        <div className="vr"></div>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={3} md={12} xl={3}>
                    <Row className="d-flex justify-content-center align-items-center">
                      <Col lg={2} md={2} xl={2} sm={2} xs={2}>
                        <i
                          className={`bi bi-person-vcard-fill text-color-primary`}
                        ></i>
                      </Col>
                      <Col
                        className="fs-6 lh-base"
                        lg={10}
                        md={10}
                        xl={10}
                        sm={10}
                        xs={10}
                      >
                        <span className="fw-light text-secondary text-label">
                          Decentralized Identity
                        </span>
                        <br />
                        <div
                          class="text-label text-wrap"
                          style={{ width: 2 + "rem" }}
                        >
                          {loggedInUser?.issuerDid || did || "-"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              <form onSubmit={handleSubmit(updateUserDetails)}>
                <Row>
                  <Col lg={4} md={12} xl={4} className="mb-4">
                    <TextField
                      name="primaryEmail"
                      label="Primary Email"
                      type="text"
                      maxLength={40}
                      resize="none"
                      value={loggedInUser?.userName || "-"}
                      disabled={true}
                    />
                  </Col>
                  <Col lg={4} md={12} xl={4} className="mb-4">
                    <Controller
                      control={control}
                      name="website"
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          mandatory={true}
                          label="Website URL"
                          type="text"
                          placeholder="Enter Website URL"
                          value={value ?? ""}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={errors?.website?.message}
                          disabled={!editable}
                        />
                      )}
                      rules={{
                        required: errorMessage?.website?.required,
                        pattern: {
                          value:
                            /^([a-zA-Z0-9.-]+)\.(com|net|co|org|in)(\/[^\s]*)?$/,
                          message: errorMessage.website.invalid,
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={12} xl={4} className="mb-4">
                    <Controller
                      control={control}
                      name="country"
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          mandatory={true}
                          label="Country"
                          type="text"
                          placeholder="Enter Country Name"
                          value={value ?? ""}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={errors?.country?.message}
                          disabled={!editable}
                        />
                      )}
                      rules={{
                        required: errorMessage.country.required,
                        pattern: {
                          value: /^[A-Za-z ]*$/,
                          message: errorMessage.country.invalid,
                        },
                      }}
                    />
                  </Col>
                  <Col lg={4} md={12} xl={4} className="mb-4">
                    <Controller
                      control={control}
                      name="address"
                      render={({ field: { onBlur, value, onChange } }) => {
                        return (
                          <TextArea
                            label="Address"
                            type="text"
                            placeholder="Enter Address here"
                            maxLength={150}
                            onBlur={onBlur}
                            error={errors?.address?.message}
                            value={value ?? ""}
                            onChange={onChange}
                            disabled={!editable}
                          />
                        );
                      }}
                      rules={{
                        pattern: {
                          value: /^[A-Za-z0-9 ,-]+$/,
                          message: errorMessage.address.invalid,
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} md={12} sm={12}>
                    <div className="d-flex justify-content-end gap-2">
                      {!editButton && (
                        <React.Fragment>
                          <button
                            type="button"
                            className="dsm-btn-secondary"
                            onClick={cancelChanges}
                            disabled={isDirty}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="dsm-btn-primary"
                            disabled={loading || !isDirty}
                          >
                            {loading ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                variant="light"
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </React.Fragment>
                      )}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
