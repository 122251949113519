import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Select from "react-select";
import { Col, Row, Spinner } from "react-bootstrap";
import { deleteServices, publishService, updateService } from "../../api/users";
import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";
import WarningIcon from "../../assets/images/warning-circle.svg";
import customStyles from "../../assets/sass/common/selectStyles";
import TextField from "../Common/TextField";
import { Controller, useForm, useWatch } from "react-hook-form";

const PublishClaims = ({ show, onHide, data, setOpenModal, refetchData }) => {
  const [addedClaimsData, setAddedClaimsData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [isMainModalVisible, setIsMainModalVisible] = useState(show);
  const [inputList, setInputList] = useState(
    data?.attributes?.map((attr) => ({ ...attr, type: attr.type })) || [
      { name: "", type: "" },
    ]
  );

  const options = [
    { value: "String", label: "String" },
    { value: "Number", label: "Number" },
    { value: "Date", label: "Date" },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      name: data?.name || "",
      version: data?.version || "",
      attributes: data?.attributes || [{ name: "", type: "" }],
    },
  });

  const errorMessage = {
    name: {
      required: "Service Name is required",
      invalid: "Enter a valid Service Name",
    },
    version: {
      required: "Version Name is required",
      invalid: "Invalid Version format. Correct format is 0.0.1",
    },
    attributeName: {
      required: "Field name is required",
      invalid: "Field name should not contain any number or special characters",
    },
    attributeType: {
      required: "Data type is required",
    },
  };

  const watchedAttributes = useWatch({
    control,
    name: "attributes",
  });

  useEffect(() => {
    setInputList(watchedAttributes);
  }, [watchedAttributes]);

  const updatedData = async (data) => {
    setLoading(true);
    setInputList(data.attributes);
    const dataToUpdate = {
      ...data,
      attributes: inputList,
    };
    try {
      const onSave = await updateService(addedClaimsData.id, dataToUpdate);

      if (onSave.status === 200) {
        setLoading(false);
        toast.success("Service has been updated", toastSuccess());
        setOpenModal(false);
        refetchData();
      } else {
        setLoading(false);
        toast.error(
          "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
          toastError()
        );
      }
    } catch (error) {
      setLoading(false);

      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
    }
  };

  const handleSubmitService = async (data) => {
    const attributeNames = inputList.map((attribute) => attribute.name);
    if (
      attributeNames.length === 0 ||
      attributeNames.some((attr) => attr === "")
    ) {
      toast.warning("No attributes added", toastWarning());
      return;
    }
    const dataToPublish = {
      serviceId: addedClaimsData.id,
      published: true,
      name: data.name,
      version: data.version,
      tag: addedClaimsData.version,
      attributes: attributeNames,
    };
    try {
      setPublishLoading(true);
      const onPublish = await publishService(dataToPublish);
      if (onPublish.status === 200) {
        setPublishLoading(false);
        toast.success("Service has been published", toastSuccess());
        refetchData();
        setConfirmModalShow(false);
        setOpenModal(false);
      } else {
        setPublishLoading(false);
        toast.error(
          "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
          toastError()
        );
      }
    } catch (error) {
      setPublishLoading(false);
      toast.warning(
        "Failed to create schema ID. Please change version or tag. ( Eg: 0.0.1 )",
        toastWarning()
      );
    }
  };

  const handlePublishClick = () => {
    setIsMainModalVisible(false);
    setConfirmModalShow(true);
  };

  const handleConfirmPublish = () => {
    setConfirmModalShow(false);
    handleSubmit(handleSubmitService)();
  };

  const handleCancelPublish = () => {
    setConfirmModalShow(false);
    setIsMainModalVisible(true);
  };

  const deleteService = async (id) => {
    try {
      const ondelete = await deleteServices(id);
      refetchData();
      toast.success("Service deleted sucessfully", toastSuccess);

      setOpenModal(false);
    } catch (error) {
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again. ",
        toastWarning
      );
    }
  };

  return (
    <>
      <Modal
        show={isMainModalVisible}
        onHide={onHide}
        size="md"
        className="dsm-modal-popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-capitalize">
              {data.published ? `${data.name}` : "Edit Digital Services"}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {!data.published && (
              <>
                <Row className="my-2">
                  <Col lg={7}>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          label="Service Name"
                          type="text"
                          placeholder="Enter Service Name"
                          value={value ?? ""}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={errors?.name?.message}
                        />
                      )}
                      rules={{
                        required: errorMessage.name.required,
                        pattern: {
                          value: /^[A-Za-z0-9 ]*$/,
                          message: errorMessage.name.invalid,
                        },
                      }}
                    />
                  </Col>
                  <Col lg={5}>
                    <Controller
                      control={control}
                      name="version"
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          label="Version"
                          type="text"
                          placeholder="Enter Version"
                          value={value ?? ""}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={errors?.version?.message}
                        />
                      )}
                      rules={{
                        required: errorMessage.version.required,
                        pattern: {
                          value: /^\d+\.\d+\.\d+$/,
                          message: errorMessage.version.invalid,
                        },
                      }}
                    />
                  </Col>
                </Row>
                <hr />
              </>
            )}

            <label className="form-label fw-bold">Attributes</label>
            {inputList.map((attribute, idx) => {
              return (
                <Row className="mb-2" key={attribute.id || idx}>
                  {data.published ? (
                    <Col lg={12}>
                      <TextField
                        name="attributeName"
                        type="text"
                        maxLength={40}
                        resize="none"
                        value={Object.values(attribute).join("")}
                        disabled={true}
                      />
                    </Col>
                  ) : (
                    <Col lg={7}>
                      <Controller
                        control={control}
                        name={`attributes.${idx}.name`}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Enter Field Name"
                            error={
                              errors?.attributes?.[idx]?.name
                                ? errors.attributes[idx].name.message
                                : false
                            }
                          />
                        )}
                        rules={{
                          required: errorMessage.attributeName.required,
                          pattern: {
                            value: /^[a-zA-Z ]+$/,
                            message: errorMessage.attributeName.invalid,
                          },
                        }}
                      />
                    </Col>
                  )}

                  <Col lg={5}>
                    {!data.published && (
                      <Controller
                        control={control}
                        name={`attributes.${idx}.type`}
                        render={({ field }) => (
                          <div className="textfield-container">
                            <Select
                              styles={customStyles(
                                errors?.attributes?.[idx]?.type
                              )}
                              options={options}
                              value={options.find(
                                (option) => option.value === field.value
                              )}
                              onChange={(selected) => {
                                field.onChange(selected.value);
                              }}
                              placeholder="Select Data Type"
                            />
                            {errors?.attributes?.[idx]?.type && (
                              <span className="inputbox-error-msg">
                                {errors.attributes[idx].type.message}
                              </span>
                            )}
                          </div>
                        )}
                        rules={{
                          required: errorMessage.attributeType.required,
                        }}
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
          </form>
        </Modal.Body>
        <Modal.Footer>
          {!data.published && (
            <>
              <button
                type="button"
                className="dsm-btn-danger-light"
                onClick={() => deleteService(data.id)}
              >
                Delete
              </button>
              <button
                type="button"
                className="dsm-btn-secondary"
                disabled={loading}
                onClick={handleSubmit(updatedData)}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" variant="dark" />
                ) : (
                  "Save As Draft"
                )}
              </button>

              <button
                type="button"
                className="dsm-btn-primary"
                onClick={handlePublishClick}
                disabled={publishLoading || isDirty}
              >
                {publishLoading ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  "Publish"
                )}
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      {/* confirm publish */}
      <Modal
        show={confirmModalShow}
        onHide={handleCancelPublish}
        size="md"
        centered
        className="dsm-modal-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src={WarningIcon} alt="warning" /> Do you want to Publish?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Once published, there will be no opportunity for further
            modifications.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="dsm-btn-secondary"
            onClick={handleCancelPublish}
          >
            Do it Later
          </button>
          <button
            type="button"
            className="dsm-btn-danger"
            onClick={handleConfirmPublish}
            disabled={publishLoading}
          >
            {publishLoading ? (
              <Spinner animation="border" size="sm" variant="dark" />
            ) : (
              "Confirm Publish"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PublishClaims;
