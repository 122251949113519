import { Navigate, createBrowserRouter } from "react-router-dom";
import Root from "./root/Root";
import Verifier from "./components/IssuedCredentials/IssuedCredentials";
import Services from "./components/Services/Services";
import Profile from "./components/Profile/Profile";
import AddNewService from "./components/Services/AddNewService";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import Notfound from "./components/Common/NotFound";
import MoreDetails from "./components/IssuedCredentials/MoreDetails";
import ManageUsers from "./components/ManageUsers/ManageUsers";
import AsyncRouteRedirect from "./components/Common/AsyncRouteRedirect"; // Import AsyncRouteRedirect
import Login from "./pages/UserLogin";

// Refactor with isUserPrimary check via AsyncRouteRedirect
const routes = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <AsyncRouteRedirect />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/services/add-service",
        element: <AddNewService />,
      },
      {
        path: "/issued-credentials",
        element: <Verifier />,
      },
      {
        path: "/manage-users",
        element: <ManageUsers />,
      },
      {
        path: "/issued-credentials/details",
        element: <MoreDetails />,
      },
    ],
  },
  {
    path: "/pin-setup",
    element: <Login />, 
  },
  {
    path: "*",
    element: <Notfound />,
  },
]);

export default routes;