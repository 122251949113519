import { useEffect, useState } from "react";
import { validateMnemonics, saverUserInfo } from "../../api/users";
import { useNavigate } from "react-router-dom";

const ConfirmRecoveryPhase = ({ setLoginProcess }) => {
  const [enteredMnemonic, setEnteredMnemonic] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const userId = localStorage.getItem("username");
  const storedMnemonic = JSON.parse(localStorage.getItem("mnemonicPhrase"));
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (enteredMnemonic.trim() === storedMnemonic.join(" ")) {
      try {
        const response = await validateMnemonics(userId, storedMnemonic);

        if (response?.data?.success) {
          const userPin = localStorage.getItem('userPIN');
          const orgName = localStorage.getItem('orgName');
          const issuerId = localStorage.getItem('verifierId');
          const cloudInfoString = localStorage.getItem('cloudInfo');
          const cloudInfo = JSON.parse(cloudInfoString);
          console.log(cloudInfo);
          const isSaved = await saverUserInfo(userId, orgName, userPin, cloudInfo.IngressAgentHost, issuerId);
          if (isSaved.data.success) {
            navigate("/profile");
          } else {
            setErrorMessage("Error intialiasing/saving issuer the problem might be from our side, Try Again");
          }
        } else {
          setErrorMessage("Validation failed. Please try again.");
        }
      } catch (error) {
        setErrorMessage("Error validating mnemonic: " + error.message);
      }
    } else {
      setErrorMessage("The mnemonic phrase you entered does not match.");
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        setEnteredMnemonic(content.trim());
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="login-form">
      <h5 className="text-secondary mb-1">Confirm Recovery Phase</h5>
      <p className="text-secondary">
        Please enter your recovery phrase below to confirm it matches.
      </p>

      {errorMessage && <p className="text-danger">{errorMessage}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
        <label className="mb-1 text-white">Upload Mnemonic File</label>
          <input
            type="file"
            onChange={handleFileUpload}
            className="form-control"
            accept=".txt"
          />
          <label className="mb-1 text-white">Enter Recovery Phrase</label>
          <textarea
            value={enteredMnemonic}
            onChange={(e) => setEnteredMnemonic(e.target.value)}
            className="form-control"
            placeholder="Enter the recovery phrase"
            rows="4"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary mt-3">
          Confirm
        </button>
      </form>
    </div>
  );
};

export default ConfirmRecoveryPhase;