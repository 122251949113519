import React, { useEffect, useRef, useState } from "react";
import Joyride, { STATUS } from "react-joyride";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
// import
import TrustGridLogo from "../assets/images/issuer_logo.svg";
import GreterThen from "../assets/images/greterthen.svg";
import LessThen from "../assets/images/lessthen.svg";
import CollapseLogo from "../assets/images/collapse-trustgrid.svg";
import Icon from "../assets/images/why-iam.svg";
import "../assets/sass/app.scss";

const Root = () => {
  const [start, setStart] = useState(false);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const sidebarRef = useRef(null);

  // click out side menu collapsed on mobile screen
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        window.innerWidth <= 768 &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setCollapsed(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  // Wizard
  const steps = [
    // side bar
    {
      target: ".profile-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Access your profile to update your details, add more information,
            and invite additional users to manage the dashboard.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".services-wiz",
      content: "Browse, Add, and Modify Services.",
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".credentials-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Please explore the credentials assigned to various holders, the
            names of the services they're linked to, and their respective
            connection DIDs [Decentralized Identifiers].
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".manage-user-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Invite user and delegate permission to manage Issuer dashboard.</p>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },

    // Profile
    {
      target: ".add-details-wiz",
      content: "Click here to add further details to your profile.",
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".edit-details-wiz",
      content: "Click here to update your profile information.",
      disableBeacon: true,
      placement: "bottom",
    },

    // all services
    {
      target: ".service-card-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            <b>Unpublished:</b> Select the card to access the service
            attributes, make edits to attributes for unpublished services, and
            proceed with publishing them.
          </p>
          <p>
            <b>Published:</b> Click on the card to view the service attributes
            for services that have already been published.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".add-new-service-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Click here to create a new service and define the attributes.</p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    // issued credentials
    {
      target: ".search-box-wiz",
      content: "Click here to search any holder",
      disableBeacon: true,
      placement: "bottom",
    },
    // manage users
    {
      target: ".invite-user-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Add new user to manage Issuer dashboard.</p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".revoke-user-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Revoke user's Dashboard management permission.</p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },

    // logout
    {
      target: ".welcome-user",
      content: "Click here to access the logout option.",
      disableBeacon: true,
    },
  ];

  // Joyride Custom Style
  const joyrideStyles = {
    options: {
      zIndex: 10000,
      arrowColor: "#fff",
      backgroundColor: "#fff",
      primaryColor: "#4398CD",
      textColor: "#000",
      width: 400,
      height: 200,
    },
    tooltip: {
      fontSize: "16px",
    },
    buttonNext: {
      backgroundColor: "#4398CD",
      color: "#fff",
    },
    buttonBack: {
      color: "#000",
    },
    buttonSkip: {
      color: "#666",
    },
  };

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action === "close") {
      // Reset the tour state
      setStart(false);
      document.body.style.overflow = "auto"; // Restore scroll
    } else {
      document.body.style.overflow = "hidden"; // Hide scroll
    }
  };

  // Define a mapping of routes to steps that should be excluded
  const routeExclusions = {
    "/profile": [".profile-wiz"],
    "/services": [".services-wiz"],
    "/services/add-service": [".services-wiz"],
    "/issued-credentials": [".credentials-wiz"],
    "/manage-users": [".manage-user-wiz"],
  };

  // Get the current route exclusions, default to an empty array
  const exclusions = routeExclusions[location.pathname] || [];

  // Filter the steps based on the current route exclusions
  const filteredSteps = steps.filter(
    (step) => !exclusions.includes(step.target)
  );
  // Wizard

  return (
    <Container fluid className="main-layout p-0">
      <Joyride
        steps={filteredSteps}
        run={start}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={false}
        showSkipButton={true}
        styles={joyrideStyles}
        callback={handleJoyrideCallback}
        locale={{
          last: "Finish",
        }}
      />
      <nav
        ref={sidebarRef}
        className={`sidebar-menu ${collapsed ? "collapsed-menu" : ""}`}
      >
        <button onClick={toggleSidebar} className="toggle-button">
          {collapsed ? (
            <img src={GreterThen} alt="logo" />
          ) : (
            <img src={LessThen} alt="logo" />
          )}
        </button>
        <div className="d-flex flex-column flex-shrink-0">
          <Link to="/">
            {collapsed ? (
              <div className="collapse-brand-logo">
                <img
                  src={CollapseLogo}
                  alt="logo"
                  width={48}
                  height={48}
                  className="img-fluid"
                />
              </div>
            ) : (
              <div className="brand-logo">
                <img src={TrustGridLogo} alt="logo" className="img-fluid" />
              </div>
            )}
          </Link>
          <ul className="nav nav-pills flex-column mb-auto nav-menu">
            <li className="nav-item">
              <NavLink to="/profile" className="profile">
                {!collapsed ? <span className="profile-wiz">Profile</span> : ""}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/services" className="services">
                {!collapsed ? (
                  <span className="services-wiz">Services</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/issued-credentials" className="credentials">
                {!collapsed ? (
                  <span className="credentials-wiz">Issued Credentials</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>{" "}
            {/* <li className="nav-item">
              <NavLink to="/manage-users" className="manage-users">
                {!collapsed ? (
                  <span className="manage-user-wiz">Manage Users</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li> */}
          </ul>
        </div>
      </nav>
      <Outlet />
      <button className="wizard-btn" onClick={() => setStart(true)}>
        <img src={Icon} alt="icon" />
        Need Help?
      </button>
    </Container>
  );
};

export default Root;
