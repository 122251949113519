import React, { useState } from "react";
import Header from "../Header/Header";
import Stepper from "../Common/Stepper";
import ConnectDataSource from "./Steps/ConnectDataSource/ConnectDataSource";
import DataSourceInformation from "./Steps/DataSourceInformation/DataSourceInformation";
import ServiceInformation from "./Steps/ServiceInformation/ServiceInformation";

const AddNewService = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [stepName, setStepName] = useState([
    "Digital Service",
    "Connect Data Source",
    "Data Source Information",
  ]);

  return (
    <>
      <div className="right-container">
        <div className="main-header">
          <Header title="Add New Service" />
        </div>

        <div className="inner-container">
        <p style={{ fontSize: 13, textAlign: "justify", backgroundColor:'white',   borderRadius: '8px',   padding: '16px',    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)'}}>
            <b>Example:</b> If a university is issuing diplomas as verifiable credentials, the schema definition might specify attributes like "name," "degree," "graduation date," and "GPA."
          </p>
          
          <div className="inner-body-container">
            <div className="body">
              <Stepper currentStep={currentStep} stepName={stepName} />
              {currentStep === 1 ? (
                <ServiceInformation setCurrentStep={setCurrentStep} />
              ) : currentStep === 2 ? (
                <ConnectDataSource setCurrentStep={setCurrentStep} />
              ) : currentStep === 3 ? (
                <DataSourceInformation setCurrentStep={setCurrentStep} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewService;
