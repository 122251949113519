import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StepperContext } from "../../../../context/Stepper/StepperContext";

const ConnectDataSource = ({ setCurrentStep }) => {
  const { setData } = useContext(StepperContext);
  const navigate = useNavigate();

  const dataSourceType = [
    // {
    //   title: "SQL Connnector",
    //   id: 0,
    //   value: "SQL Connnector",
    //   description: "Live connection to a SQL database",
    // },
    {
      title: "Webhook",
      id: 1,
      value: "Webhook",
      description: "Live connection to your API Endpoint",
    },
    // {
    //   title: "CSV Import",
    //   value: "CSV Import",
    //   id: 2,
    //   description:
    //     "One-off manual import from a CSV file (you will be able to download a CSV template on the next step)",
    // },
  ];

  const [selectedId, setSelectedId] = useState(0);

  const handleSelectionChange = (id) => {
    setData(id);
    setSelectedId(id);
  };

  return (
    <>
      <div className="connect-data-source">
        <div className="connect-data-source">
          <div className="chose-title">
            <h2>
              Choose how you would like to integrate your credential data into
              TrustGrid.
            </h2>
          </div>
          <div className="connect-data-source-list">
            <ul>
              {dataSourceType.map((selectDataSource) => {
                const { id, title, description } = selectDataSource;
                return (
                  <li
                    key={id}
                    onClick={() => handleSelectionChange(id)}
                    className={id === selectedId ? "selected-item" : ""}
                  >
                    <p>
                      <input
                        className="dsm-radio-btn"
                        type="radio"
                        id={id}
                        value={id}
                        checked={id === selectedId}
                      />
                      {title}
                    </p>
                    <small>{description}</small>
                  </li>
                );
              })}
              <li key={1} className=" bg-body-secondary disabled ">
                <p>
                  <input
                    className="dsm-radio-btn-disabled "
                    type="radio"
                    id={1}
                    value={1}
                    disabled
                  />
                  SQL Connnector
                </p>
                <small>Live connection to a SQL database</small>
              </li>
              <li key={2} className="bg-body-secondary disabled ">
                <p>
                  <input
                    className="dsm-radio-btn-disabled "
                    type="radio"
                    id={2}
                    value={2}
                    disabled
                  />
                  CSV Import
                </p>
                <small>
                  One-off manual import from a CSV file (you will be able to
                  download a CSV template on the next step)
                </small>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner-page-footer">
        <button
          type="button"
          className="dsm-btn-primary"
          onClick={() => {
            setCurrentStep(3);
          }}
        >
          Next
        </button>
        <button
          type="button"
          className="dsm-btn-secondary"
          onClick={() => {
            setCurrentStep(1);
          }}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default ConnectDataSource;
