import React from "react";

const TextArea = ({
  className = "",
  onChange,
  error = "",
  textareaResize,
  wholeEvent = false,
  isViewMode = false,
  viewPlaceholder = "",
  help = "",
  label = "",
  mandatory = false,
  ...props
}) => {
  return (
    <div
      className={`${className} textfield-container ${
        isViewMode ? "view-mode" : ""
      }`}
    >
      {label ? (
        <label className="inputbox-label">
          {label}
          {mandatory ? (
            <span className="inputbox-mandatory-mark">*</span>
          ) : null}
        </label>
      ) : null}
      {isViewMode ? (
        <pre className="view-mode-text">
          {props?.value || viewPlaceholder || props?.placeholder}
        </pre>
      ) : (
        <textarea
          rows="3"
          className={`dsm-textarea ${error ? "inputbox-error-border" : ""} ${
            textareaResize ? "resize-text-area" : ""
          }`}
          onChange={(e) => onChange(wholeEvent ? e : e.target.value)}
          {...props}
        />
      )}
      {!error && <span className="helper-text">{help}</span>}
      {error && <span className="inputbox-error-msg">{error}</span>}
    </div>
  );
};

export default TextArea;
