import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";
import { inviteUser } from "../../api/users";
import TextField from "../Common/TextField";

const InviteUser = ({ show, handleClose }) => {
  const issuerId = localStorage.getItem("issuerId");
  const networkId = localStorage.getItem("networkId");
  const [loading, setLoading] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", reValidateMode: "onChange" });

  const errorMessage = {
    email: {
      required: "Email ID is required",
      invalid: "Enter a valid Email ID",
    },
  };

  const sendInvitation = async (data) => {
    setLoading(true);
    try {
      const payload = { ...data, networkId, issuerId };
      const onCreate = await inviteUser(payload);
      if (onCreate.status === 200) {
        setLoading(false);
        toast.success("Invite sent successfully", toastSuccess());
        handleClose();
      } else {
        setLoading(false);
        toast.error(
          "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
          toastError()
        );
      }
    } catch (error) {
      setLoading(false);
      toast.warning(
        "User email ID already exists. Please add another email ID.",
        toastWarning()
      );
    }
  };

  return (
    <>
      <Modal
        onHide={handleClose}
        show={show}
        size="Md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="dsm-modal-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Invite User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Add email addresses of users you would like to give permission to
            manage services.
          </p>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value, onBlur } }) => (
              <TextField
                id="email"
                label="Email ID"
                type="email"
                placeholder="Enter Email ID"
                onChange={onChange}
                value={value ?? ""}
                onBlur={onBlur}
                error={errors?.email?.message}
              />
            )}
            rules={{
              required: errorMessage.email.required,
              pattern: {
                value: /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: errorMessage.email.invalid,
              },
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="dsm-btn-secondary"
            onClick={handleClose}
          >
            Cancel
          </button>
          {loading ? (
            <button type="button" className="dsm-btn-primary" disabled>
              <Spinner animation="border" size="sm" variant="light" />
            </button>
          ) : (
            <button
              type="button"
              className="dsm-btn-primary"
              onClick={handleSubmit(sendInvitation)}
            >
              Send Invite
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InviteUser;
