import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Spinner, Table } from "react-bootstrap";
import { getCredentialById } from "../../api/users";
import { toast } from "react-toastify";

import EmptyScreen from "../Common/EmptyScreen";
import { formatDate } from "../../util/inputvalidations";
import NoResultImage from "../../assets/images/no-result.svg";
import CopyIcon from "../../assets/images/copy.svg";
import { toastWarning } from "../Common/ToastAlert";
import { useLocation } from "react-router-dom";
import Widget from "../Common/Widget";

const MoreDetails = () => {
  const location = useLocation();
  const { id } = location.state || {};
  const [data, setData] = useState({});
  const [metadata, setmetaData] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const showData = async () => {
      try {
        setLoading(true);
        const getCredential = await getCredentialById(id);
        const res = getCredential.data.result;
        setData(res);
        setmetaData(res.metadata["_anoncreds/credential"]);
        console.log(res);
        setLoading(false);
      } catch (error) {
        //   toast.warning(
        //     "Hmm..... Something doesn't seem right. Try refreshing or logging in again.",
        //     toastWarning()
        //   );
        setLoading(false);
      }
    };

    showData();
  }, []);

  const noReulstContent = {
    heading: "Sorry! No Results Found.",
    subHeading: [
      "We couldn’t find what you were looking for. Please try other ways.",
    ],
  };

  const noHolderContent = {
    heading: "No Data available",
  };

  return (
    <>
      <div className="right-container">
        <div className="main-header">
          <Header title="Issued Credential" />
        </div>

        <div className="inner-container">
          <div className=" bigwidget">
            <div className={`card shadow text-dark  bg-opacity-25 borders-b`}>
              <div className="card-body">
                <h3 className="card-subtitle mb-2 ">
                  <i className={`bi bi-bookmark text-light-blue  mx-2`}></i>
                  Blockchain Tags
                </h3>
                <hr className="" />
                <div className="row ">
                  <div className="col-4">ID</div>
                  <div className="col-8 ">{data.id}</div>
                </div>
                <hr className="" />
                <div className="row">
                  <div className="col-4">Connection ID</div>
                  <div className="col-8 ">{data.connectionId}</div>
                </div>
                <hr className="" />
                <div className="row ">
                  <div className="col-4">Credential Definition ID</div>
                  <div className="col-8">
                    {metadata?.credentialDefinitionId}
                  </div>
                </div>
                <hr className="" />

                <div className="row ">
                  <div className="col-4">Schema ID</div>
                  <div className="col-8 ">{metadata?.schemaId}</div>
                </div>
                <hr className="" />
                <div className="row ">
                  <div className="col-4">Thread ID</div>
                  <div className="col-8">{data.threadId}</div>
                </div>
              </div>
            </div>
            <div className={`card shadow text-dark   bg-opacity-25 borders-b`}>
              <div className="card-body">
                <h3 className="card-subtitle mb-2 ">
                  <i className={`bi bi-person-vcard text-light-blue  mx-2`}></i>
                  Credential Details (Decrypted)
                </h3>

                <hr className="" />

                {data?.credentialAttributes?.map((attr) => (
                  <>
                    <div className="row">
                      <div className="col-4 text-capitalize">{attr?.name}</div>
                      <div className="col-8 text-capitalize">{attr?.value}</div>
                    </div>
                    <hr className="" />
                  </>
                ))}

                <div className="row ">
                  <div className="col-4">State</div>
                  <div className="col-8 fw-bolder text-capitalize">
                    {data.state}
                  </div>
                </div>
                <hr className="" />
                <div className="row ">
                  <div className="col-4">Last Updated</div>
                  <div className="col-8">{formatDate(data?.updatedAt)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoreDetails;
