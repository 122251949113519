import axios from "axios";

let BASE_URL = process.env.REACT_APP_BASE_URL;
let axiosInstance = axios.create({
  baseURL: BASE_URL,
});



axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function getToken() {
  return localStorage.getItem("authToken");
}

export async function getUserByEmail(data) {
  return await axiosInstance.post(`${BASE_URL}/users/getUserByEmail`, data);
}

export async function inviteUser(data) {
  return await axiosInstance.post(`${BASE_URL}/users/inviteUser`, data);
}

export async function getAllUsers(issuerId) {
  return await axiosInstance.get(`${BASE_URL}/users/issuer/${issuerId}`);
}

export async function updateUser(data) {
  return await axiosInstance.post(`${BASE_URL}/users/updateIssuer`, data);
}

export async function getUser(userId) {
  // issuer id
  return await axiosInstance.post(`${BASE_URL}/users/issuerDetails/`, {
    id: userId,
  });
}

export async function createDID() {
  return await axiosInstance.post(
    `${BASE_URL}/users/createDID/`,
    {
      // headers
    },
    {
      //
    }
  );
}

export async function setAgentConfig() {
  return await axiosInstance.post(`${BASE_URL}/wallet/setAgentConfig/`);
}

export async function getAgentConfig() {
  return await axiosInstance.get(`${BASE_URL}/wallet/getAgentConfig/`);
}

export async function initAgent() {
  return await axiosInstance.post(`${BASE_URL}/wallet/initAgent/`);
}

export async function createInvitation(userId) {
  return await axiosInstance.post(
    `${BASE_URL}/wallet/createInvitation/${userId}`
  );
}

export async function createService(data) {
  return await axiosInstance.post(`${BASE_URL}/service/create/`, data);
}

export async function deleteServices(id) {
  return await axiosInstance.delete(`${BASE_URL}/service/${id}`);
}

export async function apiConnector(data) {
  return await axiosInstance.post(`${BASE_URL}/testdb`, data);
}

export async function getAllServices(issuerId) {
  return await axiosInstance.get(`${BASE_URL}/service/issuer/${issuerId}`);
}

export async function getService(issuerId) {
  return await axiosInstance.get(`${BASE_URL}/service/issuer/${issuerId}`);
}
export async function updateService(serviceId, data) {
  return await axiosInstance.put(`${BASE_URL}/service/${serviceId}`, data);
}

export async function publishService(data) {
  return await axiosInstance.post(`${BASE_URL}/service/publish/`, data);
}

// credential data
export async function getCredentials(data) {
  return await axiosInstance.post(`${BASE_URL}/credential/`, data);
}

export async function getNetworkDetails(networkId) {
  return await axiosInstance.post(`${BASE_URL}/network/`, {
    networkId: networkId,
  });
}

export async function verifiers(networkId) {
  return await axiosInstance.post(`${BASE_URL}/verifiers/`, {
    networkId: networkId,
  });
}

export async function getIssuedCredentials() {
  return await axiosInstance.get(
    `${BASE_URL}/credential/getIssuedCredentials/`
  );
}

export async function getCredentialById(recordId) {
  return await axiosInstance.post(`${BASE_URL}/credential/getCredentialById/`, {
    recordId: recordId,
  });
}

export async function getAllOrganizationUsers(issuerId) {
  return await axiosInstance.get(`${BASE_URL}/users/list/${issuerId}`);
}

export async function deleteOrganizationUser(userId) {
  return await axiosInstance.delete(`${BASE_URL}/users/${userId}`);
}

export async function getMnemonics(userId) {
  return await axiosInstance.get(`${BASE_URL}/wallet/createMnemonics?username=${userId}`);
}

export async function validateMnemonics(userId, mnemonics) {
  return await axiosInstance.post(`${BASE_URL}/wallet/validateMnemonics`, {
    userId,
    mnemonics
  });
}

export  async function isUserPrimary(userId, orgName) {
  const response =  await axiosInstance.post(`${BASE_URL}/wallet/isUserPrimary`, {
    userName: userId
  });
  console.log(response.data.result.data);
  if (response.data.result.data) {
    createAxiosWithNewBase(response.data.result.data.cloudInfo.IngressHost);
  }
  return response.data.result;
}

export  async function saverUserInfo(userId, orgName, pin, endpoints, issuerId) {
  console.log({
    username: userId,
    orgName,
    pin,
    endpoints,
    issuerId
  })
  const response =  await axiosInstance.post(`${BASE_URL}/wallet/saveUserInfo`, {
    username: userId,
    orgName,
    pin,
    endpoints,
    issuerId
  });
  return response;
}


function createAxiosWithNewBase(base_url) {
  BASE_URL = base_url;
  // axiosInstance = axios.create({
  //   baseURL: base_url,
  // });
  // axiosInstance.interceptors.request.use(
  //   async (config) => {
  //     const token = await getToken();
  //     if (token) {
  //       config.headers.Authorization = `${token}`;
  //     }
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );
}
