import { Col, Row } from "react-bootstrap";

const Widget = ({ borderColor, icon, color, value, title, width, filter }) => {
  return (
    <div
      className={`card shadow text-dark  bg-opacity-25 borders-${borderColor}`}
      onClick={filter}
    >
      <div className="card-body">
        <h6 className="card-subtitle mb-2 text-center">
          <Row className="d-flex justify-content-center align-items-center">
            <Col lg={2} md={2} xl={2} sm={2}  xs={2}>
              <i className={`bi bi-${icon} text-${color} mx-2`}></i>
            </Col>
            <Col lg={10} md={10} xl={10} sm={10}  xs={10}> {title}</Col>
          </Row>
        </h6>
        <hr className="mx-2"/>

        <h1
          className={`col text-${color} text-center mx-4 fw-bolder display-6`}
        >
          {value}
        </h1>
      </div>
    </div>
  );
};

export default Widget;
