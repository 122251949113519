import React from "react";
import SuccessIcon from "../../assets/images/toaster/Success.svg";
import DangerIcon from "../../assets/images/toaster/Danger.svg";
import WarningIcon from "../../assets/images/toaster/Warning.svg";
import InfoIcon from "../../assets/images/toaster/Info.svg";

export function toastSuccess(additionalConfig = {}) {
  return {
    icon: () => <img src={SuccessIcon} alt="" />,
    ...additionalConfig,
  };
}
export function toastError(additionalConfig = {}) {
  return {
    icon: () => <img src={DangerIcon} alt="" />,
    ...additionalConfig,
  };
}
export function toastWarning(additionalConfig = {}) {
  return {
    icon: () => <img src={WarningIcon} alt="" />,
    ...additionalConfig,
  };
}
export function toastInfo(additionalConfig = {}) {
  return {
    icon: () => <img src={InfoIcon} alt="" />,
    ...additionalConfig,
  };
}

export const ToastWithButton = ({ message, buttonText, onButtonClick }) => {
  return (
    <div className="custom-content">
      {message}
      <button
        className="dsm-btn-secondary"
        type="button"
        onClick={onButtonClick}
      >
        {buttonText}
      </button>
    </div>
  );
};
