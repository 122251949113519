import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isUserPrimary } from "../../api/users";
import UserService from "../../keycloak/UserService";

const AsyncRouteRedirect = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserPrimaryStatus = async () => {
      const email = UserService.getUsername()?.email;
      if (!email) {
        navigate("/profile");
        return;
      }

      try {
        const response = await isUserPrimary(email);
        localStorage.setItem('username', email);
        const isPrimaryUser = response || false;

        if (isPrimaryUser) {
          localStorage.setItem('issuerID', response.data.id);
          localStorage.setItem('cloudInfo', JSON.stringify(response.data.cloudInfo));
          localStorage.setItem('orgName', response.data.orgName);
          navigate("/pin-setup");
        } else {
          navigate("/profile");
        }
      } catch (error) {
        console.error("Error checking user primary status:", error);
        navigate("/profile");
      } finally {
        setLoading(false);
      }
    };

    checkUserPrimaryStatus();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return null;
};

export default AsyncRouteRedirect;
