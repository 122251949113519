import routes from "./Router";
import { RouterProvider } from "react-router-dom";
import { StepperProvider } from "./context/Stepper/StepperContext";
import RenderOnAuthenticated from "./keycloak/RenderOnAuthenticated";
import RenderOnAnonymous from "./keycloak/RenderOnAnonymous";
import Welcome from "./pages/Welcome";

function App() {

  return (
    <>
      <RenderOnAnonymous>
        <Welcome />
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
        <StepperProvider>
          <RouterProvider router={routes} />
        </StepperProvider>
      </RenderOnAuthenticated>
    </>
  );
}

export default App;
