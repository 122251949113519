import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner, Table } from "react-bootstrap";
import Header from "../Header/Header";
import InviteUser from "./InviteUser";
import { toastWarning } from "../Common/ToastAlert";
import { getAllOrganizationUsers } from "../../api/users";
import { formatDate } from "../../util/inputvalidations";
import EmptyScreen from "../Common/EmptyScreen";
import NoDataImage from "../../assets/images/no-added-data.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import DeleteUser from "./DeleteUser";

const ManageUsers = () => {
  const issuerId = localStorage.getItem("issuerId");
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [deleteUser, setDeleteUser] = useState(null);

  const fetchAllUsers = async () => {
    try {
      setLoading(true);
      const users = await getAllOrganizationUsers(issuerId);
      const res = users.data.result;
      const sortedUser = res.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setAllUsers(sortedUser);
      setLoading(false);
    } catch (err) {
      setError(true);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllUsers();
  }, []);

  const handleOpenDeleteUser = (data) => {
    setDeleteUser(data);
    setOpenDeleteModal(true);
  };

  // No results content
  const noAddedContent = {
    heading: "No Users have been added yet in this Issuer Organization",
    subHeading: ["Send Invitation to users in the Organization"],
  };

  return (
    <React.Fragment>
      {openModal && (
        <InviteUser show={openModal} handleClose={() => setOpenModal(false)} />
      )}

      {openDeleteModal && (
        <DeleteUser
          show={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          refetchData={fetchAllUsers}
          data={deleteUser}
        />
      )}

      <div className="right-container">
        <div className="main-header">
          <Header title="Manage Users" />
        </div>

        <div className="inner-container">
          <div className="inner-body-container mb-5">
            <div className="header">
              <div className="custom-select d-flex align-items-center">
                <div className="d-flex align-content-center gap-2">
                  <button
                    type="button"
                    className="dsm-btn-primary invite-user-wiz"
                    onClick={() => setOpenModal(true)}
                  >
                    Invite User
                  </button>
                </div>
              </div>
            </div>
            <div className="body">
              <Table borderless className="requests-table submit-proof-table">
                <thead>
                  <tr>
                    <th>User Email</th>
                    <th style={{ textAlign: "center" }}>Date</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    <th style={{ textAlign: "center" }}>Revoke</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={6} rowSpan={8}>
                        <div className="mt-4 d-flex align-items-center justify-content-center">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </td>
                    </tr>
                  ) : allUsers?.length === 0 ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={6}>
                        <EmptyScreen
                          content={noAddedContent}
                          emptyScreenImage={NoDataImage}
                        />
                      </td>
                    </tr>
                  ) : (
                    allUsers?.map((user) => (
                      <tr key={user?.id}>
                        <td>
                          <span
                            style={{
                              color: "#4398CD",
                            }}
                          >
                            {user?.email}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {formatDate(user?.createdAt)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={
                              user?.active === true
                                ? "success-badge"
                                : "pending-badge"
                            }
                          >
                            {user?.active === true ? "Active" : "Pending"}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div className="d-flex justify-content-center gap-2">
                            <button
                              type="button"
                              className="dsm-btn-danger-light icon-btn revoke-user-wiz"
                              onClick={() => handleOpenDeleteUser(user)}
                            >
                              <img src={DeleteIcon} alt="icon" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageUsers;
