import { useState } from "react";

const AuthenticationCode = ({ setLoginProcess }) => {
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();

    if (pin.length !== 4 || isNaN(pin)) {
      setErrorMessage("PIN must be a 4-digit number.");
      return;
    }

    if (pin !== confirmPin) {
      setErrorMessage("PIN and Confirm PIN do not match.");
      return;
    }
    localStorage.setItem("userPIN", pin);
    setErrorMessage("");
    setLoginProcess(1);
  };

  return (
    <div className="login-form">
      <h5 className="text-secondary">Set PIN</h5>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label className="mb-1">Create PIN</label>
          <input
            type="password"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            maxLength="4"
            inputMode="numeric"
            pattern="[0-9]*"
            className="form-control"
            placeholder="Enter 4-digit PIN"
            required
          />
        </div>
        <div className="form-group">
          <label className="mb-1">Confirm PIN</label>
          <input
            type="password"
            value={confirmPin}
            onChange={(e) => setConfirmPin(e.target.value)}
            maxLength="4"
            inputMode="numeric"
            pattern="[0-9]*"
            className="form-control"
            placeholder="Confirm 4-digit PIN"
            required
          />
        </div>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
        <button type="submit" className="btn btn-primary mt-3">Continue</button>
      </form>
    </div>
  );
};

export default AuthenticationCode;