import { useEffect, useState } from "react";
import { saveAs } from 'file-saver'; // Add this line
import { getMnemonics } from "../../api/users";

const RecoveryPhase = ({ setLoginProcess }) => {
  const [keys, setKeys] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const userId = localStorage.getItem("username");

  const fetchMnemonics = async () => {
    try {
      const response = await getMnemonics(userId);
      if (response?.data?.success && response.data.data) {
        setKeys(response.data.data);
        localStorage.setItem("mnemonicPhrase", JSON.stringify(response.data.data));
      } else {
        setErrorMessage("Failed to generate recovery phrase.");
      }
    } catch (error) {
      setErrorMessage("Error fetching recovery phrase: " + error.message);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchMnemonics();
    } else {
      setErrorMessage("User ID is missing.");
    }
  }, [userId]);

  // Function to generate and download the text file
  const downloadMnemonics = () => {
    const mnemonicsText = keys.join(' '); // Combine mnemonics into a single string
    const blob = new Blob([mnemonicsText], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'mnemonic-phrase.txt'); // Trigger download
  };

  const goToNextStep = (e) => {
    e.preventDefault();
    setLoginProcess(2); 
  };

  return (
    <div className="login-form">
      <h5 className="text-secondary mb-1">Set Recovery Phase</h5>
      <p className="text-secondary">
        This recovery phrase is a one-time setup to safeguard your wallet.
        Please store it carefully for future wallet recovery.
      </p>

      {errorMessage && <p className="text-danger">{errorMessage}</p>}

      <form onSubmit={goToNextStep}>
        <div className="form-group">
          <label className="mb-1 text-white">Recovery Phrase</label>
          <div className="row">
            {keys.length > 0 ? (
              keys.map((key, i) => (
                <div className="col-lg-3 p-1" key={i}>
                  <div className="box">
                    <span>{key}</span>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-white">Loading recovery phrase...</p>
            )}
          </div>
        </div>

        {/* Add the Download Mnemonics Button here */}
        {keys.length > 0 && (
          <button
            type="button"
            className="btn btn-secondary mt-3"
            onClick={downloadMnemonics}
          >
            Download Mnemonics
          </button>
        )}

        {/* Continue Button */}
        <button type="submit" className="btn btn-primary mt-3">
          Continue
        </button>
      </form>
    </div>
  );
};

export default RecoveryPhase;