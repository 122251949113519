import React from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import TextField from "../../../../Common/TextField";
import { apiConnector } from "../../../../../api/users";
import { toastSuccess, toastWarning } from "../../../../Common/ToastAlert";
import Db from "../../../../../assets/images/db.svg";

const ApiConnector = ({ setIsAPIConnectorValid }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      apiKey: "",
    },
  });

  const onApiKeySubmit = async (data) => {
    try {
      const onTest = await apiConnector({ apikey: data.apiKey });

      if (onTest?.data?.success == false) {
        toast.warning("Oops!" + " " + onTest.data.message, toastWarning());
      } else {
        toast.success("API connection successful", toastSuccess());
      }
    } catch (error) {
      toast.warning(error.response.data.message, toastWarning());
    }
  };

  return (
    <>
      <div className="chose-title">
        <h2 className="mb-2">Set your Webhook URL, Method and Key</h2>
      </div>
      <form onSubmit={handleSubmit(onApiKeySubmit)}>
        <Row>
          <Col lg={6}>
            <div className="my-3">
              <Controller
                control={control}
                name="url"
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="URL"
                      type="text"
                      placeholder="Enter URL"
                      maxLength={40}
                      resize="none"
                      value={"https://issuerbackend.dev.trustgrid.com"}
                      disabled
                    />
                  );
                }}
              />
            </div>
            <div className="my-3">
              <Controller
                control={control}
                name="method"
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      label="Method"
                      type="text"
                      placeholder="method"
                      maxLength={40}
                      resize="none"
                      value={"POST"}
                      disabled
                    />
                  );
                }}
              />
            </div>

            <div className="my-3">
              <Controller
                control={control}
                name="apiKey"
                render={({ field: { onChange, value, onBlur } }) => {
                  return (
                    <TextField
                      label="API KEY"
                      type="text"
                      placeholder="API KEY"
                      maxLength={40}
                      resize="none"
                      onChange={onChange}
                      value={value ?? ""}
                      onBlur={onBlur}
                      error={errors?.apiKey?.message}
                    />
                  );
                }}
                rules={{
                  required: "API Key is required to establish a connection",
                  pattern: {
                    value: /^trustgrid\.database\.apihash$/,
                    message: "Please enter a valid API Key",
                  },
                }}
              />
            </div>
            <Col lg={3} className="my-4">
              <button type="submit" className="dsm-btn-secondary">
                Test
              </button>
            </Col>
          </Col>
          <Col className="p-5">
            <img src={Db} alt="empty screen img" className="img-fluid" />
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ApiConnector;
