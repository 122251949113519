import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { Spinner, Table } from "react-bootstrap";
import { getCredentials, getIssuedCredentials } from "../../api/users";
import { toast } from "react-toastify";

import EmptyScreen from "../Common/EmptyScreen";
import { formatDate } from "../../util/inputvalidations";
import NoDataImage from "../../assets/images/no-added-data.svg";
import CopyIcon from "../../assets/images/copy.svg";
import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";
import Widget from "../Common/Widget";
// show no of issued credentials
// copy icon on right
// new page
// choose two id
const IssuedCredentials = () => {
  const issuerId = localStorage.getItem("issuerId");
  const networkId = localStorage.getItem("networkId");
  const navigate = useNavigate();
  const [holderData, setHolderData] = useState([]);
  const [issuedCredentials, setIssuedCredentials] = useState([]);
  const [verifiers, setVerifiers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [count, setCount] = useState({});
  const [filteredAndSortedCred, setFilteredAndSortedCred] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const fetchHolderData = async () => {
      try {
        setLoading(true);
        const getCredential = await getCredentials({
          issuerId: issuerId,
        });
        const res = getCredential.data.result;
        setHolderData(res);
        setFilteredData(res);
        setLoading(false);
      } catch (error) {
        toast.warning(
          "Hmm......... Something doesn't seem right. Try refreshing or logging in again.",
          toastWarning()
        );
        setLoading(false);
      }
    };
    const fetchIssuedCredentials = async () => {
      try {
        setLoading(true);
        const getCredential = await getIssuedCredentials();
        const res = getCredential.data.result;
        setIssuedCredentials(res);
        setCounts(res);
        const sortedData = res.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setFilteredAndSortedCred(sortedData);
        setFilteredData(sortedData);
        setLoading(false);
      } catch (error) {
        // toast.warning(
        //   "Hmm... Something doesn't seem right. Try refreshing or logging in again.",
        //   toastWarning()
        // );
        setLoading(false);
      }
    };
    const fetchVerifiers = async () => {
      try {
        setLoading(true);
        // issuer count
        const getCredential = await verifiers(networkId);
        const res = getCredential;
        console.log(res);
        setVerifiers(res);
        setLoading(false);
      } catch (error) {
        // toast.warning(
        //   "Hmm.. Something doesn't seem right. Try refreshing or logging in again.",
        //   toastWarning()
        // );
        setLoading(false);
      }
    };
    // fetchHolderData();
    fetchIssuedCredentials();
    fetchVerifiers();
  }, []);

  const handleClickOnId = async (id) => {
    navigate("/issued-credentials/details", { state: { id: id } });
  };

  // copy DID
  const handleCopy = (id) => {
    navigator.clipboard.writeText(id).then(
      (id) => {
        toast.success("DID copied successfully", toastSuccess());
      },
      (err) => {
        toast.error("Oops! Try Again Later." + err, toastError());
      }
    );
  };

  const noReulstContent = {
    heading: "Sorry! No Results Found.",
    subHeading: [
      "We couldn’t find what you were looking for. Please try other ways.",
    ],
  };

  const noHolderContent = {
    heading: "No Issued Credentials are available",
  };

  const formatCellData = (text) => {
    if (text.length <= 6) return text;
    return text.slice(0, 3) + "..." + text.slice(-3);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setCounts = (issuedCredentials) => {
    const data = {
      done: 0,
      undone: 0,
    };
    issuedCredentials.map((x) => {
      if (x.state == "done") data.done += 1;
      else data.undone += 1;
    });
    setCount(Object.assign({}, data));
  };

  const allCredentialFilter = () => {
    setFilteredAndSortedCred(filteredData);
  };
  const verifiedCredentialFilter = () => {
    const filteredVerifiedCred = filteredData.filter(
      (item) => item.state === "done"
    );
    setFilteredAndSortedCred(filteredVerifiedCred);
  };

  const unverifiedCredentialFilter = () => {
    const filteredUnerifiedCred = filteredData.filter(
      (item) => item.state !== "done"
    );
    setFilteredAndSortedCred(filteredUnerifiedCred);
  };

  return (
    <>
      <div className="right-container">
        <div className="main-header">
          <Header title="Issued Credentials" />
        </div>

        <div className="inner-container">
          <div className="issued-credentials-card-section widget">
            <Widget
              borderColor="b"
              icon="circle-fill"
              color="blue"
              title="Issued Credentials"
              value={issuedCredentials.length ? issuedCredentials.length : "0"}
              filter={allCredentialFilter}
            />

            <Widget
              borderColor="g"
              icon="circle-fill"
              color="green"
              title="Active Credentials"
              value={count.done ? count.done : "0"}
              filter={verifiedCredentialFilter}
            />

            <Widget
              borderColor="o"
              icon="circle-fill"
              color="orange"
              title="Pending Credentials"
              value={count.undone ? count.undone : "0"}
              filter={unverifiedCredentialFilter}
            />
          </div>

          <div className="inner-body-container">
            <div className="body">
              <Table borderless className="requests-table submit-proof-table">
                <thead>
                  <tr>
                    <th>Credential ID</th>
                    {/* <th>Connection Id</th> */}
                    <th>State </th>
                    <th>Date</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={4} rowSpan={8}>
                        <div className="mt-4 d-flex align-items-center justify-content-center">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </td>
                    </tr>
                  ) : issuedCredentials.length === 0 ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={4}>
                        <EmptyScreen
                          content={noHolderContent}
                          emptyScreenImage={NoDataImage}
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {issuedCredentials?.length == 0 ? (
                        <tr className="empty-screen-tr">
                          <td colSpan={3}>
                            <EmptyScreen
                              content={noReulstContent}
                              emptyScreenImage={NoDataImage}
                            />
                          </td>
                        </tr>
                      ) : (
                        filteredAndSortedCred?.map((issuedCred) => {
                          return (
                            <tr>
                              {/* <td>
                                {issuedCred?.connectionId && (
                                  <>
                                    
                                      <img
                                        className="img-fluid copy-icon"
                                        src={CopyIcon}
                                        alt="copy"
                                        onClick={() =>
                                          handleCopy(issuedCred?.connectionId)
                                        }
                                      />
                                      <span>{issuedCred?.connectionId}</span>
                                      
                                 
                                  </>
                                )}
                              </td> */}

                              <td>
                                <img
                                  className="img-fluid copy-icon"
                                  src={CopyIcon}
                                  alt="copy"
                                  onClick={() => handleCopy(issuedCred?.id)}
                                />
                                <span>
                                  {isMobile
                                    ? formatCellData(issuedCred?.id)
                                    : issuedCred?.id}
                                </span>
                              </td>

                              <td>
                                {issuedCred?.state == "done" ? (
                                  <span className="success-badge">
                                    {issuedCred?.state}
                                  </span>
                                ) : (
                                  <span className="pending-badge">
                                    {issuedCred?.state}
                                  </span>
                                )}
                              </td>

                              <td>{formatDate(issuedCred?.updatedAt)}</td>
                              <td>
                                <span
                                  className="info-badge"
                                  onClick={() =>
                                    handleClickOnId(issuedCred?.id)
                                  }
                                >
                                  View
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IssuedCredentials;
