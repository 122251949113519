import React, { useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { CSVLink } from "react-csv";

import DownloadIcon from "../../../../../assets/images/download.svg";
import UplaodIcon from "../../../../../assets/images/upload.svg";
import { StepperContext } from "../../../../../context/Stepper/StepperContext";

const CsvImport = () => {
  const [inputs, setInputs] = useState();
  const [uploadedFile, setUploadedFile] = useState(null);

  const { addService } = useContext(StepperContext);
  // console.log(addService);

  const headers = addService.attributes.map((item) => ({
    label: item.name,
    key: item.type,
  }));

  const data = [{}];

  const handleFileChange = (event) => {
    setUploadedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!uploadedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadedFile);
    // console.log(formData);

    // fetch('/upload-csv', {
    //   method: 'POST',
    //   body: formData,
    // })
    // .then(response => response.json())
    // .then(data => {
    //   console.log(data);

    // })
    // .catch(error => {
    //   console.error(error);

    // });
  };
  return (
    <>
      <div className="chose-title">
        <h2>
          Download your CSV template and upload CSV file to Import credentials
        </h2>
      </div>
      <Row className="mt-4">
        <Col lg={3} md={6} sm={12}>
          <label className="inputbox-label mb-4">Download Schema</label>
          <br />
          <CSVLink
            data={data}
            headers={headers}
            filename="attribute-data.csv"
            className="dsm-btn-primary w-100 p-3"
          >
            Download CSV
            <img src={DownloadIcon} alt="" className="img-styles" />
          </CSVLink>
        </Col>

        <Col lg={4} md={6} sm={12}>
          <label className="inputbox-label mb-2">Upload CSV</label>
          <br />
          <button
            type="button"
            className="dsm-btn-secondary w-100"
            onClick={handleUpload}
            disabled={!uploadedFile}
          >
            <input type="file" accept=".csv" onChange={handleFileChange} />
            <img src={UplaodIcon} alt="" className="img-styles" />
          </button>
        </Col>
      </Row>
    </>
  );
};

export default CsvImport;
