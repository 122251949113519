import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import Select from "react-select";
import { StepperContext } from "../../../../context/Stepper/StepperContext";
import TextField from "../../../Common/TextField";
import DeleteIcon from "../../../../assets/images/trash.svg";
import customStyles from "../../../../assets/sass/common/selectStyles";

const options = [
  { value: "String", label: "String" },
  { value: "Number", label: "Number" },
  { value: "Date", label: "Date" },
];

const ServiceInformation = ({ setCurrentStep }) => {
  const navigate = useNavigate();
  const { addService, updateServiceData } = useContext(StepperContext);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      name: addService?.name || "",
      version: addService?.version || "",
      attributes:
        addService?.attributes?.length > 0
          ? addService.attributes
          : [{ name: "", type: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attributes",
  });

  const errorMessage = {
    name: {
      required: "Service Name is required",
      invalid: "Enter a valid Service Name",
      min: "Service Name should contain minimum of 3 characters",
    },
    version: {
      required: "Version is required",
      invalid: "Invalid Version format. Correct format is 0.0.1",
    },
    attributeName: {
      required: "Field name is required",
      invalid: "Field name should not contain any number or special characters",
    },
    attributeType: {
      required: "Data type is required",
    },
  };

  const handleNext = (data) => {
    updateServiceData({
      name: data.name,
      version: data.version,
      attributes: data.attributes,
    });
    setCurrentStep(2);
  };

  const handleBack = () => {
    reset(
      updateServiceData({
        name: "",
        version: "",
        attributes: "",
      })
    );
    navigate(-1);
  };

  const addAttribute = () => {
    append({ name: "", type: "" });
  };

  const removeAttribute = (index) => {
    remove(index);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleNext)}>
        <div className="service-information mt-2">
          <div className="p-3">
            <Row className="mb-4">
              <Col lg={4}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      mandatory={true}
                      label="Service Name"
                      type="text"
                      placeholder="Enter Service Name"
                      value={value ?? ""}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors?.name?.message}
                    />
                  )}
                  rules={{
                    required: errorMessage.name.required,
                    pattern: {
                      value: /^(?=.*[A-Za-z])[A-Za-z0-9 ]*$/,
                      message: errorMessage.name.invalid,
                    },
                    minLength: {
                      value: 3,
                      message: errorMessage.name.min,
                    },
                  }}
                />
              </Col>
              <Col lg={4}>
                <Controller
                  control={control}
                  name="version"
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      mandatory={true}
                      label="Version"
                      type="text"
                      placeholder="Enter Version"
                      value={value ?? ""}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors?.version?.message}
                    />
                  )}
                  rules={{
                    required: errorMessage.version.required,
                    pattern: {
                      value: /^\d+\.\d+\.\d+$/,
                      message: errorMessage.version.invalid,
                    },
                  }}
                />
              </Col>
            </Row>
            {fields.map((field, index) => (
              <div key={field.id}>
                <Row className="mb-2">
                  <label htmlFor="attributes" className="inputbox-label">
                    Attributes
                  </label>
                  <Col lg={4}>
                    <Controller
                      control={control}
                      name={`attributes.${index}.name`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Enter Field Name"
                          error={
                            errors?.attributes?.[index]?.name
                              ? errors.attributes[index].name.message
                              : false
                          }
                        />
                      )}
                      rules={{
                        required: errorMessage.attributeName.required,
                        pattern: {
                          value: /^[a-zA-Z ]+$/,
                          message: errorMessage.attributeName.invalid,
                        },
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <Controller
                      control={control}
                      name={`attributes.${index}.type`}
                      render={({ field }) => (
                        <div className="textfield-container">
                          <Select
                            styles={customStyles(
                              errors?.attributes?.[index]?.type
                            )}
                            options={options}
                            value={options.find(
                              (option) => option.value === field.value
                            )}
                            onChange={(selected) => {
                              field.onChange(selected.value);
                            }}
                            placeholder="Select Data Type"
                          />
                          {errors?.attributes?.[index]?.type && (
                            <span className="inputbox-error-msg">
                              {errors.attributes[index].type.message}
                            </span>
                          )}
                        </div>
                      )}
                      rules={{
                        required: errorMessage.attributeType.required,
                      }}
                    />
                  </Col>
                  <Col lg={2}>
                    {fields.length > 1 && (
                      <button
                        className="delete-icon-btn"
                        type="button"
                        onClick={() => removeAttribute(index)}
                      >
                        <img src={DeleteIcon} alt="delete icon" />
                      </button>
                    )}
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col lg={8} className="d-flex flex-row-reverse">
                <button
                  className="dsm-btn-secondary"
                  type="button"
                  onClick={addAttribute}
                >
                  + Add
                </button>
              </Col>
            </Row>
          </div>
        </div>
        <div className="inner-page-footer">
          <button type="submit" className="dsm-btn-primary">
            Next
          </button>
          <button
            type="button"
            className="dsm-btn-secondary"
            onClick={handleBack}
          >
            Back
          </button>
        </div>
      </form>
    </>
  );
};

export default ServiceInformation;
