import { Col, Row } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import TrustGridLogo from "../assets/images/issuer_logo.svg";
import LandingImage from "../assets/images/landing-img.svg";
import ItemLogo1 from "../assets/images/welcome-1.svg";
import ItemLogo2 from "../assets/images/welcome-2.svg";
import ItemLogo3 from "../assets/images/welcome-3.svg";
import "../assets/sass/common/_welcome.scss";

const Welcome = () => {
  const { handleLogin } = useAuth();
  return (
    <div className="landing-page-main container-fluid p-0">
      <div className="body">
        <div className="brand-logo">
          <img
            src={TrustGridLogo}
            alt="logo"
            className="img-fluid"
            width={240}
            height={50}
          />
        </div>
        <Row className="mb-5">
          <Col
            xl={7}
            lg={9}
            md={12}
            sm={12}
            className="order-lg-1 order-md-2 order-sm-2 order-1"
          >
            <div className="welcome-content">
              <h4>
                Welcome to the TrustGrid<sup className="sup">TM</sup> Issuer
                Center
              </h4>
              <p>
                Discover our innovative platform, engineered to empower you to
                issue secure, verifiable credentials on the blockchain. With
                unparalleled privacy, security, and integrity, it enables seamless
                integration with emerging Web3 technologies.
              </p>
              <p>
                Ideal for diverse sector-relevant applications, our Issuer Center
                allows you to onboard issuers and their respective users, create
                and publish digital services in real-time, and issue Verifiable
                Credentials. In-depth dashboard enables issuers to report
                transactional activities on Ledgers and Registries ensuring a
                robust and future-proof solution for your decentralized
                transactions.
              </p>
              <button
                className="dsm-btn-primary w-50"
                onClick={handleLogin}
              >
                Login
              </button>
            </div>
          </Col>
          <Col
            xl={5}
            lg={3}
            md={12}
            sm={12}
            className="order-lg-2 order-md-1 order-sm-1 order-2"
          >
            <div className="decentral-logo">
              <img src={LandingImage} alt="logo" />
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer">
        <div className="item">
          <div className="item-logo">
            <img src={ItemLogo1} alt="logo" className="img-fluid" />
          </div>
          <h6>Cryptographically Secured Credentials</h6>
          <p>
            Each credential issued through our Issuer Center is cryptographically
            secured, ensuring it remains unalterable and tamper-proof.
          </p>
        </div>{" "}
        <div className="item">
          <div className="item-logo">
            <img src={ItemLogo2} alt="logo" className="img-fluid" />
          </div>
          <h6>Immutable Audit Trail</h6>
          <p>
            All issued credentials are recorded on the blockchain, providing a
            permanent and immutable audit trail for enhanced transparency and
            trust.
          </p>
        </div>{" "}
        <div className="item">
          <div className="item-logo">
            <img src={ItemLogo3} alt="logo" className="img-fluid" />
          </div>
          <h6>Service Creation and Credential Issuance</h6>
          <p>
            Our platform allows you to create a variety of services. Later,
            holders can obtain verifiable credentials corresponding to these
            services, enhancing their utility and trustworthiness.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
