import React, { createContext, useContext, useState, useEffect } from "react";
import UserService from "../keycloak/UserService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    UserService.initKeycloak(() => {
      const token = UserService.getToken();
      console.log(token);
      setToken(token);
    });
  }, []);

  const handleLogin = () => {
    UserService.doLogin();
  };

  const handleLogout = () => {
    UserService.doLogout();
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ token, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
