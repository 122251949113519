import React, { useState, useMemo } from "react";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import InfoDanger from "../../assets/images/info-danger.svg";
import { deleteOrganizationUser } from "../../api/users";
import { toastSuccess, toastWarning } from "../Common/ToastAlert";

const DeleteUser = ({ show, handleClose, data, refetchData }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleDeleteUser = async (userId) => {
    try {
      setLoading(true);
      const onDelete = await deleteOrganizationUser(userId);
      toast.success("User revoked Successfully", toastSuccess());
      setLoading(false);
      refetchData();
      handleClose();
    } catch (err) {
      setError(err);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="dsm-modal-popup"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <img src={InfoDanger} alt="icon" /> Revoke Access?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Do you want to revoke the access for this user :<b> {data?.email}</b>{" "}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="dsm-btn-secondary"
          onClick={handleClose}
        >
          Dismiss
        </button>

        <button
          type="button"
          className="dsm-btn-danger"
          onClick={() => handleDeleteUser(data?.id)}
        >
          {loading ? (
            <Spinner animation="border" size="sm" variant="light" />
          ) : (
            "Yes, Revoke"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUser;
