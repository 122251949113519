import { useState, useEffect } from "react";
import AuthenticationCode from "../../components/userLogin/authenticationCode";
import RecoveryPhase from "../../components/userLogin/setRecovery";
import ConfirmRecoveryPhase from "../../components/userLogin/confirmRecoveryPhase";
import UserService from "../../keycloak/UserService"; // Your Keycloak service
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [loginProcess, setLoginProcess] = useState(0); // Tracks the phase of login
  const navigate = useNavigate();

  // Check authentication status on component mount
  useEffect(() => {
    UserService.initKeycloak(() => {
      if (UserService.isLoggedIn()) {
        // User is authenticated, initiate the PIN setup and login process
        setLoginProcess(0); // Start with the first step: PIN setup (AuthenticationCode)
      } else {
        // Redirect to the login page if not authenticated
        UserService.doLogin();
      }
    });
  }, []);

  // Handle the completion of the login process
  const handleProcessCompletion = () => {
    // Navigate to the /profile or other protected routes after completing all phases
    navigate("/profile");
  };

  // This is the login flow rendering different components based on `loginProcess`
  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100 background">
        <div className="login-container">
          <div className="login-box">
            <h2 className="logo">TrustGrid<sup>TM</sup></h2>

            {/* Render the appropriate login step based on `loginProcess` */}
            {loginProcess === 0 && (
              <AuthenticationCode setLoginProcess={setLoginProcess} />
            )}
            {loginProcess === 1 && (
              <RecoveryPhase setLoginProcess={setLoginProcess} />
            )}
            {loginProcess === 2 && (
              <ConfirmRecoveryPhase onComplete={handleProcessCompletion} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
