// customStyles.js
const customStyles = (error) => ({
  control: (provided) => ({
    ...provided,
    borderColor: error ? "#da1414" : "#E2E3E5",
    borderWidth: 1,
    borderRadius: 4,
    height: "38px",
    minHeight: "38px",
    boxShadow: "none",
    fontSize: "16px",
    "&:hover": {
      borderColor: error ? "#da1414" : "#777777",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "38px",
    fontSize: "16px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#777777",
    fontSize: "16px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "gray",
    fontSize: "16px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#777777",
    fontSize: "16px",
  }),
  menu: (provided) => ({
    ...provided,
    border: "none",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    fontSize: "16px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#0076FC" : "#F4F6F7",
    color: state.isSelected ? "#ffffff" : "#777777",
    "&:hover": {
      color: state.isSelected ? "#ffffff" : "#1c1c1c",
    },
  }),
});

export default customStyles;
