import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import SqlConnector from "./DataType/SqlConnector";
import ApiConnector from "./DataType/ApiConnector";
import CsvImport from "./DataType/CsvImport";
import { StepperContext } from "../../../../context/Stepper/StepperContext";
import { createService } from "../../../../api/users";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../Common/ToastAlert";

const DataSourceInformation = ({ setCurrentStep }) => {
  const navigate = useNavigate();
  // const issuerId = localStorage.getItem("issuerId");
  // const networkId = localStorage.getItem("networkId");
  const { data, addService, setAddService } = useContext(StepperContext);
  const [loading, setLoading] = useState();
  const [isSqlConnectorValid, setIsSqlConnectorValid] = useState(false);
  // const [isAPIConnectorValid, setIsAPIConnectorValid] = useState(false);

  useEffect(() => {
    const issuerId = localStorage.getItem("issuerId");
    const networkId = localStorage.getItem("networkId");

    if (issuerId && networkId) {
      setAddService((prevService) => ({
        ...prevService,
        issuerId,
        networkId,
      }));
    }
  }, [setAddService]);

  const createNewService = async () => {
    try {
      setLoading(true);
      const onCreate = await createService(addService);
      if (onCreate.status === 200) {
        setLoading(false);
        toast.success("Service created successfully", toastSuccess());
        navigate("/services");
        setAddService({
          // issuerId: issuerId,
          // networkId: networkId,
          issuerId: localStorage.getItem("issuerId"),
          networkId: localStorage.getItem("networkId"),
          name: "",
          version: "",
          tag: "",
          invitationURLId: 1,
          attributes: [],
        });
      } else {
        setLoading(false);
        toast.error(
          "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
          toastError()
        );
      }
    } catch (error) {
      setLoading(false);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
    }
  };

  return (
    <React.Fragment>
      <div className="dat-source-information">
        {data == 0 && (
          <SqlConnector setIsSqlConnectorValid={setIsSqlConnectorValid} />
        )}
        {data == 1 && <ApiConnector />}
        {/* {data == 1 && (
          <ApiConnector setIsAPIConnectorValid={setIsAPIConnectorValid} />
        )} */}
        {data == 2 && <CsvImport />}
      </div>

      <div className="inner-page-footer">
        {loading ? (
          <button type="button" className="dsm-btn-primary" disabled>
            <Spinner animation="border" size="sm" variant="light" />
          </button>
        ) : (
          <button
            type="button"
            className="dsm-btn-primary"
            onClick={() => createNewService()}
            // disabled={!isSqlConnectorValid && !isAPIConnectorValid}
          >
            Save
          </button>
        )}
        <button
          type="button"
          className="dsm-btn-secondary"
          onClick={() => {
            setCurrentStep(2);
          }}
        >
          Back
        </button>
      </div>
    </React.Fragment>
  );
};

export default DataSourceInformation;
