import React, { useState, createContext, useContext, useEffect } from "react";
import UserService from "../../keycloak/UserService";

export const StepperContext = createContext();

export const useStepper = () => useContext(StepperContext);

export const StepperProvider = ({ children }) => {
  const currusername = UserService.getUsernames()
  const [issuerId, setIssuerId] = useState('');
  const [networkId, setNetworkId] = useState('');

  const [username, setUsername] = useState(currusername);

  const [data, setData] = useState(0);
  const [serviceData, setServiceData] = useState([]);
  const [addedClaims, setAddedClaims] = useState([]);
  const [addService, setAddService] = useState({
    issuerId: '',
    networkId: '',
    name: "",
    version: "",
    tag: "",
    invitationURLId: 1,
    attributes: [],
  });

  const updateServiceData = (data) => {
    setAddService(prev => ({ ...prev, ...data }));
  };

  useEffect(() => {
    setAddService((prev) => ({ ...prev, issuerId: issuerId, networkId: networkId }));

  }, [issuerId, networkId]);

  return (
    <StepperContext.Provider
      value={{
        data,setData,
        serviceData, setServiceData,
        addedClaims,setAddedClaims,
        addService, updateServiceData, setAddService, 
        username, setUsername,
        networkId, setNetworkId,
        issuerId, setIssuerId
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};
